/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  DetailManualDeductAddSaldoData,
  ManualDeductAddSaldoEntities
} from "@/domain/entities/ManualDeductAddSaldo";
import { container } from "tsyringe";
import { ManualDeductAddSaldoPresenter } from "../presenters/ManualDeductAddSaldoPresenter";
import { CreateDeductSaldoApiRequest } from "@/data/payload/api/ManualDeductAddSaldoApiRequest";
import { Pagination } from "@/domain/entities/Pagination";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface ManualDeductAddSaldoState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  manualDeductAddSaldoList: any;
  detailManualDeductAddSaldo: DetailManualDeductAddSaldoData;
  openModal: boolean;
  openSuccess: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "manual_deduct_add_saldo"
})
class ManualDeductAddSaldoStore extends VuexModule
  implements ManualDeductAddSaldoState {
  public isLoading = false;
  public isLoadingDetail = false;
  public isErrorDetail = false;
  public isError = false;
  public periodeStart: any = null;
  public periodeEnd: any = null;
  public isOpenModalDownload = false;
  public isOpenModalSuccess = false;
  public search = "";
  public errorCause = "";
  public manualDeductAddSaldoList = new ManualDeductAddSaldoEntities(
    new Pagination(1, 10),
    []
  );
  public detailManualDeductAddSaldo = new DetailManualDeductAddSaldoData(
    0,
    "",
    "",
    "",
    "",
    "",
    0,
    "",
    "",
    "",
    "",
    ""
  );
  public pagination = {
    page: 1,
    limit: 10
  };
  public detailIdManualDeduct = 0;
  public openModal = false;
  public openSuccess = false;
  public advanceFilterData: any = {
    clientPartnerType: "",
    clientPartner: "",
    createdBy: "",
    type: ""
  };

  @Action
  public fetchManualDeductAddSaldoList(params: {
    page: number;
    limit: number;
    search: string;
    actorType: any;
    actorId: number;
    startDate: string;
    endDate: string;
    type: string;
    createdBy: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(ManualDeductAddSaldoPresenter);
    presenter
      .getManualDeductAddSaldoList(
        params.page,
        params.limit,
        params.search,
        params.actorType,
        params.actorId,
        params.startDate,
        params.endDate,
        params.type,
        params.createdBy
      )
      .then((res: ManualDeductAddSaldoEntities) => {
        this.setManualDeductAddSaldoList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getManualDeductAddSaldoList() {
    this.fetchManualDeductAddSaldoList({
      page: this.manualDeductAddSaldoList.pagination.page,
      limit: this.manualDeductAddSaldoList.pagination.limit,
      search: this.search,
      actorType:
        this.advanceFilterData.clientPartnerType === "partner"
          ? "pos"
          : this.advanceFilterData.clientPartnerType,
      actorId: this.advanceFilterData.clientPartner?.id || "",
      startDate: this.periodeStart
        ? new Date(this.periodeStart).toLocaleDateString("fr-CA")
        : "",
      endDate: this.periodeEnd
        ? new Date(this.periodeEnd).toLocaleDateString("fr-CA")
        : "",
      type: this.advanceFilterData.type,
      createdBy: this.advanceFilterData.createdBy?.createdId || ""
    });
  }

  @Action
  public fetchDetailManualDeductAddSaldo(id: number) {
    const presenter = container.resolve(ManualDeductAddSaldoPresenter);
    this.setLoadingDetail(true);
    presenter
      .getDetailManualDeductAddSaldo(id)
      .then((res: DetailManualDeductAddSaldoData) => {
        this.setDetailManualDeductAddSaldo(res);
        this.setErrorDetail(false);
        this.setErrorCause("");
      })
      .catch(err => {
        this.setErrorDetail(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Action
  public async searchAction(value: string) {
    await this.setSearch(value);
    await this.setFirstPage();
    await this.getManualDeductAddSaldoList();
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Mutation
  private async setFirst() {
    this.manualDeductAddSaldoList.pagination.page = 1;
  }

  @Action
  public getDeductReports(params: {
    adjustmentType: string;
    actorType: string;
    actorId: number;
    startDate: string;
    endDate: string;
    createdBy: number;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ManualDeductAddSaldoPresenter);
    presenter
      .getDeductReports(
        params.adjustmentType,
        params.actorType,
        params.actorId,
        params.startDate,
        params.endDate,
        params.createdBy
      )
      .then((res: any) => {
        const csvData = new Blob([res], { type: "text/csv;charset=utf-8;" });
        const exportFilename = "Manual Deduct/Add Saldo.csv";
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(csvData, exportFilename);
        } else {
          //In FF link must be added to DOM to be clicked
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(csvData);
          link.setAttribute("download", exportFilename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.setOpenModalSuccess(true);
      })
      .catch(err => {
        this.setOpenModalSuccess(false);
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Download Gagal!", () =>
            this.getDeductReports(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public createDeductSaldo(params: {
    actorType: string;
    actorId: number;
    adjustmentType: string;
    amount: string;
    notes: string;
    proofFile: any;
    currency: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    this.setOpenModal(false);
    const presenter = container.resolve(ManualDeductAddSaldoPresenter);
    presenter
      .createDeductSaldo(
        new CreateDeductSaldoApiRequest(
          params.actorType,
          params.actorId,
          params.adjustmentType,
          params.amount,
          params.notes,
          params.proofFile,
          params.currency
        )
      )
      .then(() => {
        this.setOpenModal(false);
        this.setOpenSuccess(true);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pembuatan Gagal !", () =>
            this.createDeductSaldo(params)
          )
        );
        this.setOpenModal(false);
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Mutation
  public setLoadingDetail(val: boolean) {
    this.isLoadingDetail = val;
  }

  @Mutation
  public setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setDetailManualDeductAddSaldo(val: DetailManualDeductAddSaldoData) {
    this.detailManualDeductAddSaldo = val;
  }

  @Mutation
  public setErrorDetail(val: boolean) {
    this.isErrorDetail = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  public setManualDeductID(id: number) {
    this.detailIdManualDeduct = id;
  }

  @Mutation
  public setManualDeductAddSaldoList(data: any) {
    this.manualDeductAddSaldoList = data;
  }

  @Mutation
  public setPeriodeStart(value: Date | null) {
    this.periodeStart = value;
  }

  @Mutation
  public setPeriodeEnd(value: Date | null) {
    this.periodeEnd = value;
  }

  @Mutation
  public setOpenModalDownload(val: boolean) {
    this.isOpenModalDownload = val;
  }

  @Mutation
  public setOpenModalSuccess(val: boolean) {
    this.isOpenModalSuccess = val;
  }
  @Mutation
  public setAdvanceFilterData(val: any) {
    this.advanceFilterData = val;
  }
}

export const ManualDeductAddSaldoController = getModule(
  ManualDeductAddSaldoStore
);
