/* eslint-disable @typescript-eslint/camelcase */
import { CreateManualDeductAddSaldoRequestInterface } from "../contracts/ManualDeductAddSaldoRequest";
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";

export class CreateDeductSaldoApiRequest
  implements CreateManualDeductAddSaldoRequestInterface {
  private actorType: string;
  private actorId: number;
  private adjustmentType: string;
  private amount: string;
  private notes: string;
  private proofFile: any;
  private currency: string;
  constructor(
    actorType: string,
    actorId: number,
    adjustmentType: string,
    amount: string,
    notes: string,
    proofFile: any,
    currency: string,
  ) {
    this.actorType = actorType;
    this.actorId = actorId;
    this.adjustmentType = adjustmentType;
    this.amount = amount;
    this.notes = notes;
    this.proofFile = proofFile;
    this.currency = currency;
  }

  public toFormData() {
    const data = {
      actor_type: this.actorType,
      actor_id: this.actorId,
      adjustment_type: this.adjustmentType,
      amount: this.amount,
      notes: this.notes,
      proof_file: this.proofFile,
      currency: this.currency
    };

    return toFormDataUtils(data);
  }
}
